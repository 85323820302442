import { render, staticRenderFns } from "./FooterSocialMedia.vue?vue&type=template&id=409532a6"
import script from "./FooterSocialMedia.vue?vue&type=script&lang=js"
export * from "./FooterSocialMedia.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconAppFacebook: require('/home/userapp/components/Atom/IconApp/IconAppFacebook.vue').default,AtomIconAppXFormerlyKnownAsTwitter: require('/home/userapp/components/Atom/IconApp/IconAppXFormerlyKnownAsTwitter.vue').default,AtomIconAppLinkedIn: require('/home/userapp/components/Atom/IconApp/IconAppLinkedIn.vue').default,AtomIconInstagram: require('/home/userapp/components/Atom/Icon/IconInstagram.vue').default,AtomIconBluesky: require('/home/userapp/components/Atom/Icon/IconBluesky.vue').default})
