//
// Note that this storage is configured as localStorage, see nuxt-vuex-localstorage in nuxt.config.js
//

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import omitBy from 'lodash/omitBy'

export const state = () => ({ profiles: [], globalMailingPreference: false })

function normalizeProfileForComparison(profile) {
  return omitBy(profile, (value, key) => value === undefined || value === null || value === '' || key === 'mailingPreference')
}

const checkMailingPreference = (profile, defaultFallback) => {
  if (!Object.hasOwn(profile, 'mailingPreference')) {
    profile.mailingPreference = defaultFallback
  }
  return profile
}

const checkAllMailingPreference = (profiles, defaultFallback) => {
  return profiles.map((profile) => checkMailingPreference(profile, defaultFallback))
}

const hasProfile = (state, profile) => {
  const normalizedProfile = normalizeProfileForComparison(profile)
  return state.profiles.find((element) => isEqual(normalizeProfileForComparison(element), normalizedProfile)) !== undefined
}

export const actions = {
  updateProfilesAndMailingPreference({ commit }, { profiles, preference }) {
    commit('setGlobalMailingPreference', preference)
    commit('mergeProfiles', profiles)
    commit('updateGlobalMailingPreference')
  },
  toggleProfile({ commit }, { profile }) {
    commit('toggleProfile', profile)
    commit('updateGlobalMailingPreference')
  },
  toggleMailing({ commit }, { profile }) {
    commit('toggleMailing', profile)
    commit('updateGlobalMailingPreference')
  },
}

export const mutations = {
  reset(state) {
    if (state.profiles.length) state.profiles = []
  },
  mergeProfiles(state, profiles) {
    // Only merge when the current profile is empty and the new profile is not empty
    // Otherwise we choose to ignore the merge request, because there is no way we can determine which version is better
    if (state.profiles.length === 0 && profiles?.length > 0) {
      state.profiles = checkAllMailingPreference(cloneDeep(profiles), state.globalMailingPreference)
    }
  },
  setGlobalMailingPreference(state, preference) {
    state.globalMailingPreference = preference
  },
  toggleProfile(state, profile) {
    if (hasProfile(state, profile)) {
      state.profiles = state.profiles.filter((element) => !isEqual(element, profile))
    } else {
      state.profiles = [...state.profiles, { ...cloneDeep(profile), mailingPreference: false }]
    }
  },
  toggleMailing(state, profile) {
    const index = state.profiles.findIndex((element) => isEqual(element, profile))
    if (index !== -1) {
      state.profiles[index].mailingPreference = !state.profiles[index].mailingPreference
    }
  },
  updateGlobalMailingPreference(state) {
    state.globalMailingPreference = state.profiles.length && state.profiles.reduce((carry, profile) => carry || profile.mailingPreference, false)
  },
}

export const getters = {
  hasProfile: (state) => (profile) => hasProfile(state, profile),
  hasProfiles: (state) => state.profiles.length > 0,
  getProfiles: (state) => state.profiles,
}
