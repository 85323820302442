import sortBy from 'lodash/sortBy'
import { truncate } from '~/assets/util'

const hitsPerPage = 10

export const state = () => {
  return {
    // Search context
    areaPreference: '*', // Corresponds with location
    currentPage: 1,
    department: '*',
    jobFunctionVolunteer: '*',
    jobFunctionPaid: '*',
    type: undefined, // Corresponds with jobOfferType

    // Search results
    facets: { areaPreferences: {}, departments: {}, jobFunctionsVolunteer: {}, jobFunctionsPaid: {} },
    hits: [],
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => hitsPerPage,

  // Search context
  currentPage: (state) => state.currentPage,
  areaPreference: (state) => state.areaPreference,
  department: (state) => state.department,
  jobFunctionVolunteer: (state) => state.jobFunctionVolunteer,
  jobFunctionPaid: (state) => state.jobFunctionPaid,
  type: (state) => state.type,

  // Url parameters
  urlParameters: (state) => ({
    areaPreference: state.areaPreference === '*' ? undefined : state.areaPreference,
    department: state.department === '*' ? undefined : state.department,
    jobFunctionVolunteer: state.jobFunctionVolunteer === '*' ? undefined : state.jobFunctionVolunteer,
    jobFunctionPaid: state.jobFunctionPaid === '*' ? undefined : state.jobFunctionPaid,
    page: state.currentPage === 1 ? undefined : state.currentPage,
  }),

  // Search results
  areaPreferences: (state) => state.facets.areaPreferences,
  departments: (state) => state.facets.departments,
  jobFunctionsVolunteer: (state) => state.facets.jobFunctionsVolunteer,
  jobFunctionsPaid: (state) => state.facets.jobFunctionsPaid,
  hits: (state) => state.hits.slice((state.currentPage - 1) * hitsPerPage, state.currentPage * hitsPerPage),
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Initialize
  initialize: (state, { areaPreference, department, page, jobFunctionVolunteer, jobFunctionPaid, type }) => {
    // When there are no values given, i.e. from the URL, we will simply keep the most recently used search context
    if (
      areaPreference === undefined &&
      department === undefined &&
      jobFunctionVolunteer === undefined &&
      jobFunctionPaid === undefined &&
      page === undefined &&
      type === undefined
    ) {
      return
    }

    state.areaPreference = typeof areaPreference === 'string' ? areaPreference : '*'
    state.department = typeof department === 'string' ? department : '*'
    state.jobFunctionVolunteer = typeof jobFunctionVolunteer === 'string' ? jobFunctionVolunteer : '*'
    state.jobFunctionPaid = typeof jobFunctionPaid === 'string' ? jobFunctionPaid : '*'
    state.currentPage = Math.max(1, typeof page === 'string' && !isNaN(Number.parseInt(page)) ? Number.parseInt(page) : 1)
    state.type = typeof type === 'string' ? type : undefined
  },

  // Search context
  reset(state) {
    state.areaPreference = '*'
    state.department = '*'
    state.jobFunctionVolunteer = '*'
    state.jobFunctionPaid = '*'
    state.currentPage = 1
    // Note that we do not reset `type` as this depends on the page and not user input
    this.dispatch('job-offer-overview/fetch')
  },
  setAreaPreference(state, areaPreference) {
    state.areaPreference = typeof areaPreference === 'string' ? areaPreference : '*'
    state.department = '*'
    state.jobFunctionVolunteer = '*'
    state.jobFunctionPaid = '*'
    state.currentPage = 1
    this.dispatch('job-offer-overview/fetch')
  },
  setDepartment(state, department) {
    state.areaPreference = '*'
    state.department = typeof department === 'string' ? department : '*'
    state.jobFunctionVolunteer = '*'
    state.jobFunctionPaid = '*'
    state.currentPage = 1
    this.dispatch('job-offer-overview/fetch')
  },
  setJobFunctionVolunteer(state, jobFunctionVolunteer) {
    state.areaPreference = '*'
    state.department = '*'
    state.jobFunctionVolunteer = typeof jobFunctionVolunteer === 'string' ? jobFunctionVolunteer : '*'
    state.jobFunctionPaid = '*'
    state.currentPage = 1
    this.dispatch('job-offer-overview/fetch')
  },
  setJobFunctionPaid(state, jobFunctionPaid) {
    state.areaPreference = '*'
    state.department = '*'
    state.jobFunctionVolunteer = '*'
    state.jobFunctionPaid = typeof jobFunctionPaid === 'string' ? jobFunctionPaid : '*'
    state.currentPage = 1
    this.dispatch('job-offer-overview/fetch')
  },
  setCurrentPage(state, page) {
    state.currentPage = Math.max(1, typeof page === 'number' && !isNaN(page) ? page : 1)
    this.dispatch('job-offer-overview/fetch')
  },

  // Search results
  setFacets: (state, facets) => (state.facets = facets),
  setHits: (state, hits) => (state.hits = Array.isArray(hits) ? hits : []),
  setTotalHits: (state, totalHits) => (state.totalHits = typeof totalHits === 'number' ? totalHits : 0),
}

export const actions = {
  async fetchFacets({ commit, dispatch, getters }) {
    const { type } = getters
    const key = `job-offer-overview;facets;type:${type}`
    const ttl = 900

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query jobOfferFacets($site:[String], $section:[String], $type:[String]) {
          elasticSearch(site:$site, section:$section, jobOfferTypeFacet:$type) {
            facets(limit:128) {
              areaPreferences: location {
                value
              }
              jobFunctionsVolunteer: jobOfferTypeVolunteer {
                value
              }
              jobFunctionsPaid: jobOfferTypePaid {
                value
              }
              departments: departmentTitle {
                value
              }
            }
          }
        }`,
        variables: {
          section: 'jobOffer',
          site: 'default',
          type,
        },
      })

      return {
        areaPreferences: sortBy(
          elasticSearch.facets.areaPreferences.map(({ value }) => ({ value, label: truncate(value, { length: 50, omission: '...' }) })),
          ({ label }) => label.toLowerCase()
        ),
        departments: sortBy(
          elasticSearch.facets.departments.map(({ value }) => ({ value, label: truncate(value, { length: 50, omission: '...' }) })),
          ({ label }) => label.toLowerCase()
        ),
        jobFunctionsVolunteer: sortBy(
          elasticSearch.facets.jobFunctionsVolunteer.map(({ value }) => ({ value, label: truncate(value, { length: 50, omission: '...' }) })),
          ({ label }) => label.toLowerCase()
        ),
        jobFunctionsPaid: sortBy(
          elasticSearch.facets.jobFunctionsPaid.map(({ value }) => ({ value, label: truncate(value, { length: 50, omission: '...' }) })),
          ({ label }) => label.toLowerCase()
        ),
      }
    }

    // Fetch the facets
    commit(
      'setFacets',
      await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { areaPreferences: {}, departments: {}, jobFunctionsVolunteer: {}, jobFunctionsPaid: {} } }, { root: true })
    )
  },

  async fetch({ commit, dispatch, getters }) {
    const { areaPreference, department, jobFunctionVolunteer, jobFunctionPaid, type } = getters
    const key = `job-offer-overview;hits;type:${type};areaPreference:${areaPreference};department:${department};jobFunctionVolunteer:${jobFunctionVolunteer};jobFunctionPaid:${jobFunctionPaid}`
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query jobOfferHits($site:[String], $section:[String], $type:[String], $areaPreference:[String], $department:[String], $jobFunctionVolunteer:[String], $jobFunctionPaid:[String]) {
          elasticSearch(site:$site, section:$section, jobOfferTypeFacet:$type, locationFacet:$areaPreference, departmentTitleFacet:$department, jobOfferTypeVolunteerFacet:$jobFunctionVolunteer, jobOfferTypePaidFacet:$jobFunctionPaid, orderBy: "postDate desc") {
            count
            hits(limit:1024) {
             uri
            }
          }
        }`,
        variables: {
          areaPreference: areaPreference === '*' ? undefined : areaPreference,
          department: department === '*' ? undefined : department,
          jobFunctionVolunteer: jobFunctionVolunteer === '*' ? undefined : jobFunctionVolunteer,
          jobFunctionPaid: jobFunctionPaid === '*' ? undefined : jobFunctionPaid,
          section: 'jobOffer',
          site: 'default',
          type,
        },
      })

      return { hits: elasticSearch.hits.map(({ uri }) => uri), totalHits: elasticSearch.count }
    }

    // Fetch the hits
    const { hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { hits: [], totalHits: 0 } }, { root: true })
    commit('setHits', hits)
    commit('setTotalHits', totalHits)
  },
}
